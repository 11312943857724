import React from 'react';
import Footer from '../components/Footer';

const ReservationPage = () => {
  return (
    <div>
      <iframe
        src="https://forms.gle/b5g1crBisiPDfGSm7"
        width="100%"
        height="800"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    <Footer/>
    </div>
  );
};

export default ReservationPage;
